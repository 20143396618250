import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import { ILXTeaser, ITeaserOverlayStyle } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, IPlacementItem, isCMCollection } from '@typesApp/cmsPlacement/Placement'

export const getTeaserItem = (placement?: IPlacement | ICMCollection): IPlacementItem => {
  const areItemsInPlacement = placement && 'items' in placement
  const item = (placement as IPlacement)?.items?.[0]
  const teasableItems = (placement as ICMCollection)?.teasableItems?.[0]

  return areItemsInPlacement ? item : (teasableItems as IPlacementItem)
}

export const isShoppableTeaser = (teaser: ILXTeaser | undefined): boolean => {
  if (!teaser) return false
  const hotZones = teaser.hotZones
  return Array.isArray(hotZones) && hotZones.length > 0
}

export const hasOverlay = (item: ILXTeaser | undefined): boolean => {
  return Boolean(item?.teaserOverlay2Style?.includes('shadow'))
}

export const getToLink = (item: ILXTeaser | undefined) => {
  const { teaserLXCallToActionSettings } = item || {}
  return `${teaserLXCallToActionSettings?.[0]?.target?.formattedUrl ?? ''}`
}

export const hasTermsAndConditions = (item: ILXTeaser | undefined): boolean =>
  Boolean(item?.targetsTermsAndConditions?.target)

export const getCollectionTextOverlayStyle = (item: ICMCollection | ICMPlaceholder): ITeaserOverlayStyle =>
  isCMCollection(item) ? (item.collectionTextOverlayStyle ? item.collectionTextOverlayStyle : '') : ''
