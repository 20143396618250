import { CMS_MODULES as CM, DEFAULT_VIEW_TYPE } from '@components/Cms/constants'
import { CONTAINER_V2_TYPES, CONTAINER_V2_TYPES_DEFAULT_VIEW } from '@components/Cms/PlacementsSwitch/constants'
import { IPlacementItem, isCMCollection } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const showCollectionText = (teaser: IPlacementItem, placementViewType: IViewType) => {
  if (!teaser) return false

  let excludedModules = [
    CM.COMBO_MINI_SLIDER,
    ...(placementViewType === DEFAULT_VIEW_TYPE ? CONTAINER_V2_TYPES_DEFAULT_VIEW : CONTAINER_V2_TYPES),
  ]
  const collection = isCMCollection(teaser)
  const viewType = teaser?.viewtype ?? ''
  return collection && viewType !== CM.MINI_SLIDER && !excludedModules.includes(viewType)
}

export const showCollectionCta = (teaser: IPlacementItem, placementViewType: IViewType) => {
  if (!teaser) return false

  const collection = isCMCollection(teaser)
  const excludedModules = placementViewType === DEFAULT_VIEW_TYPE ? CONTAINER_V2_TYPES_DEFAULT_VIEW : CONTAINER_V2_TYPES
  return collection && !excludedModules.includes(teaser.viewtype)
}
