import React from 'react'
import { StyledTypography } from '../../UI'
import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV1 } from '../../../constants/ui'
import {
  ILXTeaserName,
  TeaserBackGround,
  TeaserOverlayStyle,
  TOverlaySettings,
  TOverlayTextAlign,
} from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { teaserOverlaySettingsToCSS, teaserOverlayStyleToColor } from '../../../utils/placements'
import { Swiper } from 'swiper/react'
import { mapControllerVariant, mapShapeVariant, mapVariant } from '../../UI/Carousel/Carousel.style'
import { ColorVariant, ShapeVariant } from '../../UI/Carousel/Carousel.type'
import { variantCollectionMap, variantMap } from './PromoBanner.style'

export const StyledTextModuleWrapper = styled('div', {
  name: 'TextModuleV2',
  slot: 'Wrapper',
  shouldForwardProp: prop =>
    prop !== 'istextoverlay' &&
    prop !== 'termsconditionvisible' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'overlaysettings' &&
    prop !== 'teaseroverlaystyle' &&
    prop !== 'teaserbackground' &&
    prop !== 'viewtype' &&
    prop !== 'placement',
})<{
  istextoverlay?: 1 | 0
  termsconditionvisible?: 1 | 0
  overlaytxtalign?: TOverlayTextAlign
  overlaysettings?: TOverlaySettings
  teaseroverlaystyle?: TeaserOverlayStyle
  teaserbackground?: TeaserBackGround
  viewtype?: IViewType
  placement?: IViewType
}>(({ overlaysettings, istextoverlay, teaseroverlaystyle, theme, placement }) => ({
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  width: '100%',
  pointerEvents: istextoverlay === 1 ? 'none' : 'auto',
  position: istextoverlay === 1 ? 'absolute' : 'static',
  zIndex: istextoverlay === 1 ? Z_INDEX_LV1 : 'unset',
  padding: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(16)} ${theme.spacing(44)}`,
    ...teaserOverlaySettingsToCSS(
      istextoverlay === 1 ? overlaysettings || '' : '' || '',
      istextoverlay,
      '18%',
      '36%',
      '64px'
    ),
  },
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
    ...teaserOverlaySettingsToCSS(
      istextoverlay === 1 ? overlaysettings || '' : '' || '',
      istextoverlay,
      '18%',
      '36%',
      placement === 'pdp-banner' ||
        placement?.includes('landscape-banner-') ||
        placement?.includes('landscape-promo') ||
        placement?.includes('top-page-banner-') ||
        placement?.includes('top-page-promo')
        ? '64px'
        : '180px 64px 64px 64px'
    ),
  },
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(16)} ${theme.spacing(3)}`,
    ...teaserOverlaySettingsToCSS(
      istextoverlay === 1 ? overlaysettings || '' : '' || '',
      istextoverlay,
      '18%',
      '36%',
      '64px'
    ),
  },
}))

export const StyledTextModuleWrapperPlainSlider = styled(StyledTextModuleWrapper, {
  name: 'TextModuleV2',
  slot: 'WrapperPlainSlider',
  shouldForwardProp: prop =>
    prop !== 'istextoverlay' &&
    prop !== 'termsconditionvisible' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'overlaysettings' &&
    prop !== 'teaseroverlaystyle' &&
    prop !== 'teaserbackground' &&
    prop !== 'viewtype' &&
    prop !== 'placement',
})<{
  istextoverlay?: 1 | 0
  termsconditionvisible?: 1 | 0
  overlaytxtalign?: TOverlayTextAlign
  overlaysettings?: TOverlaySettings
  teaseroverlaystyle?: TeaserOverlayStyle
  teaserbackground?: TeaserBackGround
  viewtype?: IViewType
  placement?: IViewType
}>(({ istextoverlay, teaseroverlaystyle, theme }) => ({
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  width: '100%',
  position: istextoverlay === 1 ? 'absolute' : 'static',
  zIndex: istextoverlay === 1 ? Z_INDEX_LV1 : 'unset',
  [theme.breakpoints.up(320)]: {
    paddingBlock: 0,
  },
}))

export const StyledTextThreeBoardWrapper = styled('div', {
  name: 'TextModuleV2',
  slot: 'ThreeBoardWrapper',
  shouldForwardProp: prop =>
    prop !== 'istextoverlay' &&
    prop !== 'termsconditionvisible' &&
    prop !== 'overlaytxtalign' &&
    prop !== 'overlaysettings' &&
    prop !== 'teaseroverlaystyle' &&
    prop !== 'teaserbackground',
})<{
  istextoverlay?: 1 | 0
  termsconditionvisible?: 1 | 0
  overlaytxtalign?: TOverlayTextAlign
  overlaysettings?: TOverlaySettings
  teaseroverlaystyle?: TeaserOverlayStyle
  teaserbackground?: TeaserBackGround
}>(({ overlaysettings, istextoverlay, teaseroverlaystyle, theme }) => ({
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  width: '100%',
  position: istextoverlay === 1 ? 'absolute' : 'static',
  zIndex: istextoverlay === 1 ? Z_INDEX_LV1 : 'unset',
  padding: theme.spacing(6),
  'p:nth-of-type(2)': {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(16)} ${theme.spacing(44)}`,
    ...teaserOverlaySettingsToCSS(
      istextoverlay === 1 ? overlaysettings || '' : '' || '',
      istextoverlay,
      '18%',
      '36%',
      '64px'
    ),
  },
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(8)} 0 0`,
    ...teaserOverlaySettingsToCSS(
      istextoverlay === 1 ? overlaysettings || '' : '' || '',
      istextoverlay,
      '18%',
      '36%',
      '180px 64px 64px 64px'
    ),
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(4)} 0`,
    ...teaserOverlaySettingsToCSS(
      istextoverlay === 1 ? overlaysettings || '' : '' || '',
      istextoverlay,
      '18%',
      '36%',
      '180px 64px 64px 64px'
    ),
    'p:nth-of-type(2)': {
      marginTop: theme.spacing(2),
    },
  },
}))

export const StyledTermsWrapper = styled(StyledTextModuleWrapper, {
  name: 'TextModuleV2',
  slot: 'TermsWrapper',
  shouldForwardProp: prop => prop !== 'termsconditionvisible',
})(({ theme, termsconditionvisible }) => ({
  padding: `0 ${theme.spacing(11)} ${theme.spacing(termsconditionvisible === 0 ? 8 : 12)} ${theme.spacing(11)}`,

  [theme.breakpoints.up(769)]: {
    bottom: 'unset',
    padding: '0',
  },
}))

export const StyledTextModuleFullWidthWrapper = styled(StyledTermsWrapper, {
  name: 'TextModuleV2',
  slot: 'FullWidthWrapper',
  shouldForwardProp: prop => prop !== 'viewtype',
})<{ viewtype?: IViewType }>(({ viewtype, theme }) => ({
  [theme.breakpoints.down(769)]: {
    paddingTop: 0,
  },
  [theme.breakpoints.up(769)]: {
    marginTop: theme.spacing(16),
    left:
      viewtype === 'fullwidth-banner-collection-promo' || viewtype === 'fullwidth-banner-collection-promo-multiple'
        ? theme.spacing(15)
        : 'none',
    padding: 'unset',
  },
  [theme.breakpoints.up('md')]: {
    left: theme.spacing(15),
  },

  [theme.breakpoints.up('xs')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export const StyledTextModuleLandscapeWrapper = styled(StyledTermsWrapper, {
  name: 'TextModuleV2',
  slot: 'LandscapeWrapper',
  shouldForwardProp: prop => prop !== 'viewtype',
})<{
  viewtype?: IViewType
}>(({ viewtype, theme }) => ({
  color: theme.palette.custom.black,
  [theme.breakpoints.down(769)]: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.up(769)]: {
    color: theme.palette.custom.black,
    left:
      viewtype === 'landscape-banner-collection-promo' || viewtype === 'landscape-banner-collection-promo-multiple'
        ? theme.spacing(15)
        : 'none',
    padding: 'unset',
  },
  [theme.breakpoints.up('md')]: {
    left: theme.spacing(15),
  },
}))

export const StyledTextModuleTopPageWrapper = styled(StyledTermsWrapper, {
  name: 'TextModuleV2',
  slot: 'TopPageWrapper',
  shouldForwardProp: prop => prop !== 'viewtype',
})<{
  viewtype?: IViewType
}>(({ viewtype, theme }) => ({
  color: theme.palette.custom.black,
  [theme.breakpoints.down(769)]: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.up(769)]: {
    color: theme.palette.custom.black,
    left:
      viewtype === 'top-page-banner-collection-promo' || viewtype === 'top-page-banner-collection-promo-multiple'
        ? theme.spacing(15)
        : 'none',
    padding: 'unset',
  },
  [theme.breakpoints.up('md')]: {
    left: theme.spacing(15),
  },
}))

export const StyledSwiper = styled(Swiper, {
  name: 'TextModuleV2',
  slot: 'Swiper',
  shouldForwardProp: prop => prop !== 'shapevariant' && prop !== 'colorvariant' && prop !== 'darkmode',
})<{
  colorvariant?: ColorVariant
  shapevariant?: ShapeVariant
  darkmode?: number
}>(({ theme, colorvariant = 'light', shapevariant = 'round', darkmode = 0 }) => ({
  '.swiper-button-prev, .swiper-button-next': {
    width: theme.spacing(16),
    height: '104%', //Just a container
    top: 18,
    '&:after': {
      color: mapVariant(theme, colorvariant, shapevariant).text,
      fontSize: mapShapeVariant(theme, shapevariant).fontSize,
      borderRadius: mapShapeVariant(theme, shapevariant).borderRadius,
      background: mapVariant(theme, colorvariant, shapevariant).background,
      width: 32,
      fontWeight: 'bolder',
      boxShadow: mapShapeVariant(theme, shapevariant).boxShadow,
      display: 'flex',
      alignItems: 'center',
      height: mapShapeVariant(theme, shapevariant).height,
    },
    '&:hover:after': {
      background: mapVariant(theme, colorvariant, shapevariant).hoverBackground,
      color: mapVariant(theme, colorvariant, shapevariant).hoverText,
    },
    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  '.swiper-button-next': {
    right: '0',
    background: mapControllerVariant(theme, darkmode === 1 ? 'dark' : 'light', 'to left').buttonBackground,
    '&:after': {
      padding: '10px 14px',
    },
  },
  '.swiper-button-prev': {
    left: '0px',
    background: mapControllerVariant(theme, darkmode === 1 ? 'dark' : 'light', 'to right').buttonBackground,
    '&:after': {
      padding: '10px 12px',
    },
  },
}))

export const StyledTextModuleContainerWithBorder = styled(StyledTextModuleWrapper, {
  name: 'TextModuleV2',
  slot: 'ContainerWithBorder',
})(({ theme }) => ({
  padding: `${theme.spacing(13)} 0 !important`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(4)} !important`,
  },
}))

export const StyledTextModuleContainer = styled('article', {
  name: 'TextModuleV2',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'viewtype',
})<{ viewtype?: IViewType }>(({ viewtype }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  justifyContent:
    viewtype === 'mod-box-without-margin-products' ||
    viewtype === 'mod-box-with-margin-products' ||
    viewtype === 'mod-box-without-margin' ||
    viewtype === 'mod-box-with-margin'
      ? 'center'
      : 'default',
}))

export const StyledTitle = styled(StyledTypography, {
  name: 'TextModuleV2',
  slot: 'Title',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'viewtype',
})<{
  textAlign: React.CSSProperties['textAlign']
  viewtype?: IViewType | undefined
}>(({ theme, textAlign }) => ({
  fontSize: '1.25rem',
  fontWeight: '600',
  lineHeight: 1.5,
  textAlign: textAlign,
  spacing: theme.spacing(6),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    textAlign,
    lineHeight: 1.4,
  },
}))

export const StyledTitlePlainSlider = styled(StyledTypography, {
  name: 'TextModuleV2',
  slot: 'TitlePlainSlider',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'viewtype',
})<{
  textAlign: React.CSSProperties['textAlign']
  viewtype?: IViewType | undefined
}>(({ theme, textAlign }) => ({
  fontSize: '1.25rem',
  fontWeight: theme.spacing(175),
  lineHeight: theme.spacing(7.5),
  textAlign: textAlign,
  spacing: theme.spacing(6),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down(769)]: {
    fontSize: '1rem',
    marginBottom: theme.spacing(2),
    lineHeight: theme.spacing(6),
  },
}))

export const StyledFullWidthTitle = styled(StyledTitle, {
  name: 'TextModuleV2',
  slot: 'FullWidthTitle',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'viewtype',
})<{
  textAlign: React.CSSProperties['textAlign']
  viewtype?: IViewType
}>(({ theme, textAlign, viewtype }) => ({
  textAlign: textAlign,
  spacing: theme.spacing(6),
  [theme.breakpoints.up(0)]: {
    textAlign,
    textTransform: viewtype !== 'fullwidth-promo-banner' ? 'uppercase' : 'none',
    fontWeight: 700,
    fontSize:
      viewtype?.includes('-promo-banner-2') ||
      viewtype?.includes('-promo-2') ||
      viewtype?.includes('-promo-banner-3') ||
      viewtype?.includes('-promo-3') ||
      viewtype === 'fullwidth-promo-banner-4' ||
      viewtype === 'fullwidth-banner-collection-promo' ||
      viewtype === 'landscape-banner-collection-promo' ||
      viewtype === 'top-page-banner-collection-promo'
        ? '1.25rem'
        : '1.5rem',
    lineHeight:
      viewtype?.includes('-promo-banner-2') ||
      viewtype?.includes('-promo-2') ||
      viewtype?.includes('-promo-banner-3') ||
      viewtype?.includes('-promo-3') ||
      viewtype === 'fullwidth-promo-banner-4' ||
      viewtype === 'fullwidth-banner-collection-promo' ||
      viewtype === 'landscape-banner-collection-promo' ||
      viewtype === 'top-page-banner-collection-promo'
        ? theme.spacing(6)
        : theme.spacing(7.5),
    maxWidth: 'none',
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.up(769)]: {
    textTransform: 'none',
    maxWidth: 'none',
    fontWeight: 'auto',
    fontSize: '2rem',
    lineHeight: 1.5,
    marginBottom: theme.spacing(10),
  },
  [theme.breakpoints.between(769, 'md')]: {
    marginBottom: viewtype === 'fullwidth-promo-banner-4' ? theme.spacing(5) : theme.spacing(10),
  },
}))

export const StyledLandscapeTitle = styled(StyledFullWidthTitle, {
  name: 'TextModuleV2',
  slot: 'LandscapeTitle',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'viewtype',
})<{
  textAlign: React.CSSProperties['textAlign']
  viewtype?: IViewType
}>(({ theme }) => ({
  [theme.breakpoints.up(0)]: {
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.up(769)]: {
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.between(769, 'md')]: {
    marginBottom: theme.spacing(6),
  },
}))

export const StyledTopPageTitle = styled(StyledFullWidthTitle, {
  name: 'TextModuleV2',
  slot: 'TopPageTitle',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign']
}>(({ theme, viewtype }) => ({
  [theme.breakpoints.up(0)]: {
    marginBottom: theme.spacing(8.875),
  },
  [theme.breakpoints.up(769)]: {
    marginBottom: viewtype === 'top-page-promo-2' ? theme.spacing(0) : theme.spacing(4),
  },
  [theme.breakpoints.between(769, 'md')]: {
    marginBottom: theme.spacing(6),
  },
}))

export const StyledPreTitle = styled(StyledTypography, {
  name: 'TextModuleV2',
  slot: 'PreTitle',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign']
}>(({ textAlign, theme }) => ({
  fontSize: '1rem',
  paddingBottom: theme.spacing(6),
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign,
  },
}))

export const StyledFullWidthPreTitle = styled(StyledTypography, {
  name: 'TextModuleV2',
  slot: 'FullWidthPreTitle',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign']
}>(({ textAlign, theme }) => ({
  paddingBottom: theme.spacing(2),
  textAlign: textAlign || 'center',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: theme.spacing(6),

  [theme.breakpoints.up(769)]: {
    fontWeight: 'auto',
    fontSize: '1.5rem',
    lineHeight: 'auto',
    maxWidth: 'none',
  },
}))

export const StyledCTAContainer = styled('div', {
  name: 'TextModuleV2',
  slot: 'CTAContainer',
  shouldForwardProp: prop => prop !== 'overlayTextAlign' && prop !== 'ctaFlexDirection',
})<{
  overlayTextAlign: React.CSSProperties['justifyContent']
  ctaFlexDirection?: 'row' | 'column'
}>(({ theme, overlayTextAlign, ctaFlexDirection }) => {
  let newAlignment: string
  switch (overlayTextAlign) {
    case 'left':
      newAlignment = 'flex-start'
      break
    case 'center':
      newAlignment = 'center'
      break
    case 'right':
      newAlignment = 'flex-end'
      break
    default:
      newAlignment = 'center'
  }
  const ctaUiStyle = {
    width: 'inherit',
    minWidth: theme.spacing(32.5),
    margin: '2px auto',
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down(769)]: {
      width: '100%',
      margin: theme.spacing(1.5, 0),
    },
  }

  return {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: newAlignment,
    alignItems: overlayTextAlign,
    flexDirection: ctaFlexDirection,
    pointerEvents: 'auto',

    a: ctaUiStyle,
    button: ctaUiStyle,

    [theme.breakpoints.down(769)]: {
      width: '100%',
    },
    [theme.breakpoints.up(0)]: {
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    [theme.breakpoints.up(769)]: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      width: 'max-content',
      padding: 0,
    },
  }
})

export const StyledFullWidthCTAContainer = styled(StyledCTAContainer, {
  name: 'TextModuleV2',
  slot: 'FullWidthCTAContainer',
  shouldForwardProp: prop => prop !== 'alignment',
})<{ alignment?: 'left' | 'center' | 'right' }>(({ theme, alignment }) => ({
  textAlign: alignment || 'center',
  a: {
    [theme.breakpoints.up(0)]: {
      width: theme.spacing(58.75),
      height: theme.spacing(5.5),
      minWidth: theme.spacing(32.5),
    },
    [theme.breakpoints.up(769)]: {
      width: 'inherit',
      height: theme.spacing(7.5),
    },
  },
}))

export const TwoColumnCTAContainer = styled(StyledCTAContainer, {
  name: 'TextModuleV2',
  slot: 'TwoColumnCTAContainer',
})(() => ({
  padding: 0,
  a: {
    width: '100%',
  },
}))

export const TextContainerWrapper = styled('div', {
  name: 'TextModuleV2',
  slot: 'TextContainerWrapper',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign']
}>(({ textAlign, theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign,
  },
}))

export const TextFullWidthContainerWrapper = styled(TextContainerWrapper, {
  name: 'TextModuleV2',
  slot: 'TextFullWidthContainerWrapper',
})(({ theme }) => ({
  [theme.breakpoints.up(769)]: {
    width: 'max-content',
  },
}))

export const TextLandscapeContainerWrapper = styled(TextContainerWrapper, {
  name: 'TextModuleV2',
  slot: 'TextLandscapeContainerWrapper',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up(769)]: {
    width: 'max-content',
  },
}))

export const TextTopPageContainerWrapper = styled(TextContainerWrapper, {
  name: 'TextModuleV2',
  slot: 'TextTopPageContainerWrapper',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up(769)]: {
    width: 'max-content',
  },
}))

export const StyledCTAContainerPlainSlider = styled(StyledCTAContainer, {
  name: 'TextModuleV2',
  slot: 'CTAContainerPlainSlider',
})(({ theme }) => ({
  border: 'none',
  [theme.breakpoints.up(769)]: {
    flexDirection: 'row !important',
    '& > a': {
      width: theme.spacing(29.5),
      minWidth: theme.spacing(29.5),
      maxWidth: theme.spacing(30),
      height: theme.spacing(9.5),
      padding: 0,
    },
  },
  [theme.breakpoints.down(769)]: {
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
    '& > a': {
      width: theme.spacing(71.5),
      maxWidth: theme.spacing(72),
      height: theme.spacing(9.5),
      minHeight: theme.spacing(9.5),
      padding: 0,
    },
  },
}))

export const StyledCTAContainerOnlyChild = styled(StyledCTAContainer, {
  name: 'TextModuleV2',
  slot: 'CTAContainerOnlyChild',
})(({ theme }) => ({
  [theme.breakpoints.down(769)]: {
    '& > a:only-child': {
      maxWidth: theme.spacing(72),
    },
  },
  [theme.breakpoints.up('md')]: {
    '& > a': {
      maxWidth: theme.spacing(30),
    },
    '& > a:only-child': {
      maxWidth: '100%',
    },
  },
}))

export const StyledCTAContainerForProductCarousel = styled(StyledCTAContainer, {
  name: 'TextModuleV2',
  slot: 'CTAContainerForProductCarousel',
})(({ theme }) => ({
  width: '100% !important',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down(1500)]: {
    flexDirection: 'row !important',
  },
  a: {
    width: 'unset',
    ':only-child': {
      height: 'unset',
    },
  },
  maxWidth: 'unset !important',
  marginBottom: `${theme.spacing(16)} !important`,
  [theme.breakpoints.down(769)]: {
    flexDirection: 'column !important',
    marginBottom: `${theme.spacing(10)} !important`,
  },
}))

export const TextContainer = styled('div', {
  name: 'TextModuleV2',
  slot: 'TextContainer',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign?: React.CSSProperties['textAlign']
}>(({ textAlign, theme }) => ({
  marginBottom: `${theme.spacing(6)}`,
  p: {
    margin: '0',
  },
  textAlign: textAlign,
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
    textAlign,

    '.align--left': {
      textAlign: 'left',
    },
    '.align--center': {
      textAlign: 'center',
    },
    '.align--right': {
      textAlign: 'right',
    },
    '.align--justify': {
      textAlign: 'justify',
    },
  },
  h1: {
    fontSize: '3.75rem',
  },
  h2: {
    fontSize: '2.5rem',
  },
  h3: {
    fontSize: '2rem',
  },
  '.text-small': {
    fontSize: '0.75rem',
  },
  '.text-large': {
    fontSize: '2.5rem',
  },
}))

export const TextContainerPlainSliderContent = styled('div', {
  name: 'TextModuleV2',
  slot: 'TextContainerPlainSliderContent',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign?: React.CSSProperties['textAlign']
}>(({ textAlign, theme }) => ({
  p: {
    margin: '0',
  },
  textAlign: textAlign,
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}))

export const MultipleTeaserPromoContainer = styled('div', {
  name: 'TextModuleV2',
  slot: 'MultipleTeaserPromoContainer',
  shouldForwardProp: prop =>
    prop !== 'textAlign' && prop !== 'variant' && prop !== 'viewtype' && prop !== 'hasCode' && prop !== 'breakpoint',
})<{
  textAlign: React.CSSProperties['textAlign']
  variant: 'teaserTitle2' | 'teaserTitle3' | 'teaserTitle4' | 'teaserText1' | 'teaserText2'
  viewtype?: IViewType
  hasCode?: boolean
  breakpoint?: string
}>(({ textAlign, variant, viewtype, hasCode, theme }) => ({
  textAlign: 'center',
  p: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
  },
  [theme.breakpoints.up(0)]: {
    textAlign,
    fontSize: variantMap(variant, theme, viewtype, hasCode, 'fontSize', 'xs'),
    fontWeight: variantMap(variant, theme, viewtype, hasCode, 'fontWeight', 'xs'),
    lineHeight: variantMap(variant, theme, viewtype, hasCode, 'lineHeight', 'xs'),
    maxWidth:
      viewtype?.includes('-banner-collection-promo-multiple') && variant === 'teaserTitle3'
        ? theme.spacing(21)
        : 'none',
    margin: 'auto',
    marginBottom: viewtype?.includes('-banner-collection-promo-multiple') && !hasCode ? theme.spacing(4) : 'auto',
  },
  [theme.breakpoints.up(769)]: {
    textAlign,
    fontSize: variantMap(variant, theme, viewtype, hasCode, 'fontSize'),
    fontWeight: variantMap(variant, theme, viewtype, hasCode, 'fontWeight'),
    lineHeight: variantMap(variant, theme, viewtype, hasCode, 'lineHeight'),
    maxWidth: 'none',
    margin: 'auto',
  },
}))

export const TextPromoContainer = styled('div', {
  name: 'TextModuleV2',
  slot: 'TextPromoContainer',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'viewtype',
})<{
  textAlign: React.CSSProperties['textAlign']
  viewtype?: IViewType
}>(({ textAlign, viewtype, theme }) => ({
  marginBottom: theme.spacing(4),
  display: 'inline-block',
  div: {
    display: 'grid',
    gridTemplateColumns: viewtype === 'fullwidth-promo-banner-4' ? '0.5fr 0.5fr' : '0.1fr 1fr 0.5fr',
    gridTemplateRows: viewtype === 'fullwidth-promo-banner-4' ? '0.3r 0.3fr 0.3fr' : '0.5fr 0.5fr',
    gridTemplateAreas:
      viewtype === 'fullwidth-promo-banner-4'
        ? `
    "content1 content2"
    "content1 content2"
    "content1 content3"
    `
        : `
    "content1 content2 ."
    ". content2 content3"
    `,
    gap: '0px 0px',
    height: 'max-content',
    width: 'max-content',
    padding: 0,
    lineHeight: 0.8,
    'span:nth-of-type(1)': {
      gridArea: 'content1',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontWeight: viewtype === 'fullwidth-promo-banner-4' ? 400 : 700,
        fontSize: viewtype === 'fullwidth-promo-banner-4' ? '7.5rem' : '3.125rem',
      },
      [theme.breakpoints.up(769)]: {
        fontWeight: viewtype === 'fullwidth-promo-banner-4' ? 400 : 700,
        fontSize: viewtype === 'fullwidth-promo-banner-4' ? '12.5rem' : '3.75rem',
      },
    },
    'span:nth-of-type(2)': {
      gridArea: 'content2',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontWeight: viewtype === 'fullwidth-promo-banner-4' ? 400 : 600,
        fontSize: viewtype === 'fullwidth-promo-banner-4' ? '3.75rem' : '6rem',
      },
      [theme.breakpoints.up(769)]: {
        fontWeight: viewtype === 'fullwidth-promo-banner-4' ? 400 : 600,
        fontSize: '7.5rem',
      },
    },
    'span:nth-of-type(3)': {
      gridArea: 'content3',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontWeight: viewtype === 'fullwidth-promo-banner-4' ? 600 : 700,
        fontSize: viewtype === 'fullwidth-promo-banner-4' ? '1.875rem' : '2.5rem',
      },
      [theme.breakpoints.up(769)]: {
        fontWeight: 700,
        fontSize: viewtype === 'fullwidth-promo-banner-4' ? '3rem' : '3.75rem',
      },
    },
  },
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign,
  },
  h1: {
    fontSize: '3.75rem',
  },
  h2: {
    fontSize: '2.5rem',
  },
  h3: {
    fontSize: '2rem',
  },
  '.text-small': {
    fontSize: '0.75rem',
  },
  '.text-large': {
    fontSize: '2.5rem',
  },
}))

export const Divider = styled('div', {
  name: 'TextModuleV2',
  slot: 'Divider',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign']
}>(({ textAlign, theme }) => ({
  display: 'inline-block',
  textAlign: textAlign,
  borderLeft: `1px solid ${theme.palette.custom.light1.grey}`,
  marginLeft: '-3px',
  top: 0,
  lineHeight: 1,
  [theme.breakpoints.up(0)]: {
    height: '64px',
  },
  [theme.breakpoints.up(769)]: {
    height: '80px',
  },
}))

export const TextPromoWrapper = styled('div', {
  name: 'TextModuleV2',
  slot: 'TextPromoWrapper',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'viewtype',
})<{
  textAlign: React.CSSProperties['textAlign']
  viewtype?: IViewType
}>(({ theme, textAlign, viewtype }) => ({
  display: 'inline-block',
  marginBottom: viewtype?.includes('-banner-collection-promo-multiple') ? theme.spacing(4) : 0,
  textAlign: textAlign,
  verticalAlign: 'middle',
  lineHeight: 1,
  [theme.breakpoints.up(0)]: {
    height: viewtype?.includes('-banner-collection-promo-multiple') ? theme.spacing(18) : 'auto',
    paddingRight: viewtype?.includes('-banner-collection-promo-multiple') ? theme.spacing(0.85) : theme.spacing(2),
    paddingLeft: viewtype?.includes('-banner-collection-promo-multiple') ? theme.spacing(0.85) : theme.spacing(2),
  },
  [theme.breakpoints.up(769)]: {
    height: 'auto',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    height: 'auto',
    paddingRight: theme.spacing(4.5),
    paddingLeft: theme.spacing(4.5),
  },
}))

export const TextPromoCollectionContainer = styled('div', {
  name: 'TextModuleV2',
  slot: 'TextPromoCollectionContainer',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'variantName',
})<{
  textAlign: React.CSSProperties['textAlign']
  variantName?: ILXTeaserName
}>(({ textAlign, variantName, theme }) => ({
  display: 'inline-block',
  div: {
    display: 'grid',
    gridTemplateColumns: variantCollectionMap(variantName as ILXTeaserName, theme, 'gridTemplateColumns'),
    gridTemplateAreas: variantCollectionMap(variantName as ILXTeaserName, theme, 'gridTemplateAreas'),
    gap: '0px 0px',
    height: 'max-content',
    width: 'max-content',
    padding: 0,
    [theme.breakpoints.up(0)]: {
      gridTemplateRows: variantCollectionMap(variantName as ILXTeaserName, theme, 'gridTemplateRows', 1, 'xs'),
      marginTop: variantName === 'PromoInfoTextv1-3' ? `-${theme.spacing(1)}` : 0,
    },
    [theme.breakpoints.up(769)]: {
      gridTemplateRows: variantCollectionMap(variantName as ILXTeaserName, theme, 'gridTemplateRows'),
      margin: 0,
    },
    'span:nth-of-type(1)': {
      gridArea: 'content1',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 1, 'xs'),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 1, 'xs'),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 1, 'xs'),
      },
      [theme.breakpoints.up(769)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 1),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 1),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 1),
      },
    },
    'span:nth-of-type(2)': {
      gridArea: 'content2',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 2, 'xs'),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 2, 'xs'),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 2, 'xs'),
      },
      [theme.breakpoints.up(769)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 2),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 2),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 2),
      },
    },
    'span:nth-of-type(3)': {
      gridArea: 'content3',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 3, 'xs'),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 3, 'xs'),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 3, 'xs'),
      },
      [theme.breakpoints.up(769)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 3),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 3),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 3),
      },
    },
    'span:nth-of-type(4)': {
      gridArea: 'content4',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 4, 'xs'),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 4, 'xs'),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 4, 'xs'),
      },
      [theme.breakpoints.up(769)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 4),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 4),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 4),
      },
    },
    'span:nth-of-type(5)': {
      gridArea: 'content5',
      width: 'max-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(0)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 5, 'xs'),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 5, 'xs'),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 5, 'xs'),
      },
      [theme.breakpoints.up(769)]: {
        fontSize: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontSize', 5),
        fontWeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'fontWeight', 5),
        lineHeight: variantCollectionMap(variantName as ILXTeaserName, theme, 'lineHeight', 5),
      },
    },
  },
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign,
  },
  p: {
    margin: 0,
    fontWeight: 700,
    [theme.breakpoints.up(0)]: {
      fontSize: '1.5rem',
      lineHeight: theme.spacing(7.5),
    },
    [theme.breakpoints.up(769)]: {
      fontSize: '3.75rem',
      lineHeight: theme.spacing(18.75),
    },
  },
  h1: {
    fontSize: '3.75rem',
  },
  h2: {
    fontSize: '2.5rem',
  },
  h3: {
    fontSize: '2rem',
  },
  '.text-small': {
    fontSize: '0.75rem',
  },
  '.text-large': {
    fontSize: '2.5rem',
  },
}))

export const StyledProductContainer = styled('div', {
  name: 'TextModuleV2',
  slot: 'ProductContainer',
})(() => ({
  width: '460px',
}))

export const StyledSwiperContent = styled('div', {
  name: 'TextModuleV2',
  slot: 'SwiperContent',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const BannerPreTitle = styled('div', {
  name: 'TextModuleV2',
  slot: 'BannerPreTitle',
})(({ theme }) => ({
  color: theme.palette.custom.black,
  fontSize: '1.5rem',
  lineHeight: theme.spacing(7.5),
  fontWeight: '700px',
}))

export const StyledBannerPreTitle = styled('div', {
  name: 'TextModuleV2',
  slot: 'StyledBannerPreTitle',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'textColor',
})<{
  textAlign: React.CSSProperties['textAlign']
  textColor?: string
}>(({ theme, textAlign, textColor }) => ({
  textAlign: textAlign,
  color: textColor || theme.palette.custom.black,
  fontSize: '1.5rem',
  fontWeight: '700',
  lineHeight: theme.spacing(7.5),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down(769)]: {
    fontSize: '1rem',
    lineHeight: theme.spacing(6),
    fontWeight: '400',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  [theme.breakpoints.between(769, 897)]: {
    marginBottom: theme.spacing(2),
  },
}))

export const StyledBannerTitle = styled('div', {
  name: 'TextModuleV2',
  slot: 'BannerTitle',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'textColor',
})<{
  textAlign: React.CSSProperties['textAlign']
  textColor?: string
}>(({ theme, textAlign, textColor }) => ({
  textAlign: textAlign,
  color: textColor || theme.palette.custom.black,
  fontSize: '2.5rem',
  fontWeight: '700',
  lineHeight: theme.spacing(10),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down(769)]: {
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: theme.spacing(7.5),
    marginBottom: theme.spacing(2),
  },
}))

export const StyledBannerTextContainer = styled('div', {
  name: 'TextModuleV2',
  slot: 'BannerTextContainer',
  shouldForwardProp: prop => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign']
}>(({ textAlign, theme }) => ({
  marginBottom: theme.spacing(6),
  p: {
    margin: '0',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  textAlign: 'center',
  [theme.breakpoints.up(769)]: {
    textAlign,
  },
  [theme.breakpoints.between(769, 897)]: {
    marginBottom: theme.spacing(3),
  },
  h1: {
    fontSize: '3.75rem',
  },
  h2: {
    fontSize: '2.5rem',
  },
  h3: {
    fontSize: '2rem',
  },
  '.text-small': {
    fontSize: '0.75rem',
  },
  '.text-large': {
    fontSize: '2.5rem',
  },
}))

export const StyledTextModuleWrapperBanner = styled(StyledTextModuleFullWidthWrapper, {
  name: 'TextModuleV2',
  slot: 'TextModuleWrapperBanner',
})(({ theme }) => ({
  [theme.breakpoints.down(769)]: {
    paddingBottom: '50px !important',
  },
  [theme.breakpoints.up(769)]: {
    marginLeft: '64px !important',
  },
}))
