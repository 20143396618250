import { CMS_MODULES as CM } from '@components/Cms/constants'
import { ILXTeaser, ITeaserBackGround } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'

export type TeaserBackgroundColor = ITeaserBackGround | React.CSSProperties['backgroundColor']

export const isTeaserBackgroundColor = (backgroundColor: TeaserBackgroundColor) => backgroundColor?.startsWith('bg-')

export const getPlacementBackgroundColor = ({ placement, teaser }): TeaserBackgroundColor => {
  const excludedModules = [CM.BOX_AND_2_PRODUCTS, CM.BOX_AND_4_PRODUCTS]

  const placementBgColor = (placement as IPlacement)?.backgroundColor
  const teaserBgColor = (teaser as ILXTeaser)?.teaserBackground
  const isProductBanner = [CM.DCW_PRODUCTS].includes(teaser?.type ?? '')
  const backgroundColor = isProductBanner ? 'bg-light-secondary' : placementBgColor || teaserBgColor

  const hasBackgroundColor =
    backgroundColor !== '' && isTeaserBackgroundColor(backgroundColor) && !excludedModules.includes(placement.viewType)

  return hasBackgroundColor ? backgroundColor : 'transparent'
}
