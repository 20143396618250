import React from 'react'
import styled from '@mui/material/styles/styled'
import {
  StyledTextModuleWrapper,
  StyledTextModuleContainer,
  StyledTitle,
  StyledCTAContainer,
  TextContainer,
  StyledPreTitle,
  StyledTextModuleFullWidthWrapper,
  StyledTextThreeBoardWrapper,
  StyledProductContainer,
  StyledSwiperContent,
  TextPromoContainer,
  MultipleTeaserPromoContainer,
  TextContainerWrapper,
  TextPromoCollectionContainer,
  TextPromoWrapper,
  Divider,
  StyledSwiper,
  StyledTextModuleContainerWithBorder,
  StyledTermsWrapper,
  StyledFullWidthTitle,
  StyledFullWidthPreTitle,
  TextFullWidthContainerWrapper,
  StyledCTAContainerPlainSlider,
  StyledTextModuleWrapperPlainSlider,
  TextContainerPlainSliderContent,
  StyledTitlePlainSlider,
  StyledTextModuleLandscapeWrapper,
  TextLandscapeContainerWrapper,
  StyledLandscapeTitle,
  StyledTextModuleTopPageWrapper,
  StyledTopPageTitle,
  TextTopPageContainerWrapper,
  StyledFullWidthCTAContainer,
} from './TextModule.style'
import { ModulesProps } from '../../../types/cms'
import { ILXTeaserName, IPlacement, isCMCollection } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser, ITeaserOverlayStyle } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { getTeaserOverlayTextAlign } from '@utils/placements'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import { multipleTeaserPropsByView, teaserPropsByView } from '../../../utils/placements'
import { ProductItemWithSlide } from '../../CmsPlacement/ProductCarousel/ProductItem'
import { Navigation } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import TermsAndConditionsCta from '../../CmsPlacement/TermsAndConditionsV2'
import { CmsCountdown } from '@components/Cms/CmsComponents-CSS/CmsCountdown'
import countdownStyles from './styles/Countdown.module.scss'
export interface ITextModuleProps extends ModulesProps {
  showCta?: boolean
  placement?: IPlacement | ICMCollection
  teaser?: ILXTeaser
}

const getTextModuleContainer = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
    case 'fullwidth-banner-collection-promo':
    case 'fullwidth-banner-collection-promo-multiple':
      return StyledTextModuleFullWidthWrapper
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
    case 'landscape-banner-collection-promo':
    case 'landscape-banner-collection-promo-multiple':
      return StyledTextModuleLandscapeWrapper
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
    case 'top-page-banner-collection-promo':
    case 'top-page-banner-collection-promo-multiple':
      return StyledTextModuleTopPageWrapper
    case 'pdp-banner':
      return StyledTermsWrapper
    case 'two-item-board-carousel':
    case 'three-item-board-carousel':
      return StyledTextThreeBoardWrapper
    case 'mod-box-without-margin-products':
    case 'mod-box-without-margin':
      return StyledTextModuleContainerWithBorder
    case 'plain-slider':
      return StyledTextModuleWrapperPlainSlider
    default:
      return StyledTextModuleWrapper
  }
}

const getTextContainerWrapper = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
    case 'fullwidth-banner-collection-promo':
    case 'fullwidth-banner-collection-promo-multiple':
      return TextFullWidthContainerWrapper
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
    case 'landscape-banner-collection-promo':
    case 'landscape-banner-collection-promo-multiple':
      return TextLandscapeContainerWrapper
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
    case 'top-page-banner-collection-promo':
    case 'top-page-banner-collection-promo-multiple':
      return TextTopPageContainerWrapper
    default:
      return TextContainerWrapper
  }
}

const textContainerSelector = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
      return TextPromoContainer
    case 'fullwidth-banner-collection-promo':
    case 'fullwidth-banner-collection-promo-multiple':
    case 'landscape-banner-collection-promo':
    case 'landscape-banner-collection-promo-multiple':
    case 'top-page-banner-collection-promo':
    case 'top-page-banner-collection-promo-multiple':
      return TextPromoCollectionContainer
    case 'plain-slider':
      return TextContainerPlainSliderContent
    default:
      return TextContainer
  }
}

const getStyledTitle = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
    case 'fullwidth-banner-collection-promo':
    case 'fullwidth-banner-collection-promo-multiple':
      return StyledFullWidthTitle
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
    case 'landscape-banner-collection-promo':
    case 'landscape-banner-collection-promo-multiple':
      return StyledLandscapeTitle
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
    case 'top-page-banner-collection-promo':
    case 'top-page-banner-collection-promo-multiple':
      return StyledTopPageTitle
    case 'plain-slider':
      return StyledTitlePlainSlider
    default:
      return StyledTitle
  }
}

const getStyledPreTitle = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
    case 'fullwidth-banner-collection-promo':
    case 'fullwidth-banner-collection-promo-multiple':
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
    case 'landscape-banner-collection-promo':
    case 'landscape-banner-collection-promo-multiple':
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
    case 'top-page-banner-collection-promo':
    case 'top-page-banner-collection-promo-multiple':
      return StyledFullWidthPreTitle
    default:
      return StyledPreTitle
  }
}

const getStyledCTAContainer = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
    case 'fullwidth-banner-collection-promo':
    case 'fullwidth-banner-collection-promo-multiple':
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
    case 'landscape-banner-collection-promo':
    case 'landscape-banner-collection-promo-multiple':
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
    case 'top-page-banner-collection-promo':
    case 'top-page-banner-collection-promo-multiple':
      return StyledFullWidthCTAContainer
    case 'plain-slider':
      return StyledCTAContainerPlainSlider
    default:
      return StyledCTAContainer
  }
}

const isTextModuleOverlay = (
  viewtype: IViewType,
  isDesktop?: boolean,
  _isTablet?: boolean,
  _isTabletL?: boolean
): boolean => {
  switch (viewtype) {
    case 'fullwidth-promo-banner':
    case 'fullwidth-promo-banner-2':
    case 'fullwidth-promo-banner-3':
    case 'fullwidth-promo-banner-4':
    case 'fullwidth-banner-collection-promo':
    case 'fullwidth-banner-collection-promo-multiple':
    case 'full-width-banner-with-shadow':
    case 'landscape-promo-banner':
    case 'landscape-promo-banner-2':
    case 'landscape-promo-banner-3':
    case 'landscape-banner-collection-promo':
    case 'landscape-banner-collection-promo-multiple':
    case 'top-page-promo':
    case 'top-page-promo-2':
    case 'top-page-promo-3':
    case 'top-page-banner-collection-promo':
    case 'top-page-banner-collection-promo-multiple':
      return !!_isTabletL || !!isDesktop
    case 'pdp-banner':
      return !!_isTabletL || !!isDesktop
    case 'two-item-board-carousel':
    default:
      return false
  }
}

const ContentCmsIcon = styled('div')<{ viewtype?: IViewType; alignment?: string }>(({ theme, viewtype, alignment }) => {
  let newAlignment: string
  const textAlign = alignment === 'center' ? 'center' : 'left'
  switch (alignment) {
    case 'left':
      newAlignment = 'flex-start'
      break
    case 'center':
      newAlignment = 'center'
      break
    case 'right':
      newAlignment = 'flex-end'
      break
    default:
      newAlignment = 'center'
  }
  return {
    alignSelf: newAlignment,
    textAlign: textAlign,
    width: theme.spacing(33.75),
    marginBottom: viewtype !== 'mod-box-with-margin-products' ? theme.spacing(12) : theme.spacing(6),
    [theme.breakpoints.up(0)]: {
      marginBottom: theme.spacing(12),
    },
    [theme.breakpoints.up(769)]: {
      marginBottom: theme.spacing(12),
    },
  }
})

const TextModule: React.FC<ITextModuleProps> = ({ viewType, teaserIndex, showCta = true, placement, teaser }) => {
  const TextWrapper = textContainerSelector(placement?.viewtype || viewType || 'default')
  const { isDesktop, isMobile, isTabletWidthBelowOrEqualTo768, isTablet, isTabletWidthAbove768 } = useBreakpoints()
  const areItemsInPlacement = placement && 'items' in placement

  const moduleCollection = (placement as ICMCollection)?.teasableItems?.find(isCMCollection)
  const moduleCollectionTeaser = moduleCollection?.teasableItems
  const moduleCollectionViewType = moduleCollection?.viewtype
  let moduleTeaser = !!teaser ? teaser : areItemsInPlacement ? placement?.items[0] : placement?.teasableItems[0]
  if (!moduleCollection && moduleTeaser?.type !== 'LXTeaser') {
    return null
  }
  moduleTeaser = moduleTeaser as ILXTeaser

  const productTeaser =
    (placement as IPlacement)?.items?.length > 0 && (placement as IPlacement).items[1]
      ? (placement as IPlacement).items[1]
      : null
  const termsAndConditionsVisible = !moduleTeaser?.targetsTermsAndConditions?.target?.detailText ? 0 : 1
  const returnViewType = placement?.viewtype

  const placementHasBackGroundColor =
    !!(placement as IPlacement)?.backgroundColor && (placement as IPlacement)?.backgroundColor !== ''
  const parentbg = placementHasBackGroundColor ? (placement as IPlacement)?.backgroundColor?.split('-')[1] : 'light'
  const media = moduleTeaser?.media?.[0] ?? undefined

  const isTextOverlay =
    !!media && isTextModuleOverlay(viewType || returnViewType || 'default', isDesktop, isTablet, isTabletWidthAbove768)
      ? 1
      : 0

  const { teaserTitle, teaserText, teaserOverlaySettings, teaserOverlayTextAlign, teaserOverlayStyle } =
    teaserPropsByView(viewType || returnViewType || 'default')

  const { teaserTitle2, teaserTitle3, teaserTitle4, teaserText2 } = multipleTeaserPropsByView(
    viewType || returnViewType || 'default'
  )

  const { promoteToH1, teaserLXCallToActionSettings, teaserPreTitle, teaserBackground, teaserIcon } = moduleTeaser

  const teaserTitleValue = moduleTeaser[teaserTitle]
  const teaserTitleValue2 = moduleTeaser[teaserTitle2]
  const teaserTitleValue3 = moduleTeaser[teaserTitle3]
  const teaserTitleValue4 = moduleTeaser[teaserTitle4]
  const teaserTextValue = moduleTeaser[teaserText]
  const teaserTextValue2 = moduleTeaser[teaserText2]
  const teaserOverlaySettingsValue = moduleTeaser[teaserOverlaySettings]
  const teaserOverlayTextAlignValue = moduleTeaser[teaserOverlayTextAlign]
  const teaserOverlayStyleValueCMS = moduleTeaser[teaserOverlayStyle]
  const teaserbgvalue = placementHasBackGroundColor ? '' : teaserBackground

  const isTopPage = returnViewType?.includes('top-page-')
  const isPromo =
    returnViewType === 'fullwidth-promo-banner' ||
    returnViewType === 'landscape-promo-banner' ||
    returnViewType === 'top-page-promo'
  const isPromo2 = returnViewType?.includes('-promo-banner-2') || returnViewType?.includes('-promo-2')
  const isPromo3 = returnViewType?.includes('-promo-banner-3') || returnViewType?.includes('-promo-3')
  const isPromo4 = returnViewType === 'fullwidth-promo-banner-4'
  const isBannerCollection =
    returnViewType === 'fullwidth-banner-collection-promo' ||
    returnViewType === 'landscape-banner-collection-promo' ||
    returnViewType === 'top-page-banner-collection-promo'
  const isBannerMultiple = returnViewType?.includes('-banner-collection-promo-multiple')

  const teaserTitleAccess2 = isPromo2 || isPromo3 ? true : false
  const teaserTitleAccess3 = isPromo2 || isPromo3 ? true : false
  const teaserTitleAccess4 =
    isPromo || isPromo2 || isPromo3 || isPromo4 || isBannerCollection || isBannerMultiple ? true : false
  const teaserTextAccess2 = isPromo || isPromo2 || isPromo3 || isPromo4 ? true : false

  const getOverlayTextAlign = (textAlign: React.CSSProperties['textAlign'], viewType: IViewType) => {
    switch (viewType) {
      case 'two-item-board-carousel':
      case 'three-item-board-carousel':
        return isMobile ? 'center' : textAlign
      default:
        return textAlign || 'center'
    }
  }

  const multipleTeaserDisplay = (
    currentTeaser: string,
    variant: 'teaserTitle2' | 'teaserTitle3' | 'teaserTitle4' | 'teaserText2' | 'teaserText1',
    hasCode?: boolean
  ) => {
    return (
      <MultipleTeaserPromoContainer
        data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
        dangerouslySetInnerHTML={{ __html: currentTeaser }}
        textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
        variant={variant}
        viewtype={returnViewType}
        hasCode={hasCode}
      />
    )
  }

  const getOverlayStyle = (teaserOverlayStyleValueCMS: ITeaserOverlayStyle, viewtype: IViewType) => {
    switch (viewtype) {
      case 'pdp-banner':
        return isTabletWidthAbove768 || isDesktop ? teaserOverlayStyleValueCMS : 'text-dark-primary'
      default:
        return teaserOverlayStyleValueCMS
    }
  }
  const TextModuleWrapper = getTextModuleContainer(viewType || returnViewType || 'default')
  const TextContainerWrapper = getTextContainerWrapper(viewType || returnViewType || 'default')
  const StyledTitle = getStyledTitle(viewType || returnViewType || 'default')
  const StyledPreTitle = getStyledPreTitle(viewType || returnViewType || 'default')
  const StyledCTAContainer = getStyledCTAContainer(viewType || returnViewType || 'default')
  const teaserOverlayStyleValue = getOverlayStyle(teaserOverlayStyleValueCMS, viewType || returnViewType || 'default')
  const teaserOverlayTextAlignValueCMS = getOverlayTextAlign(
    teaserOverlayTextAlignValue,
    viewType || returnViewType || 'default'
  )
  const isFullWidth = isPromo || isPromo2 || isPromo3 || isPromo4 || isBannerCollection || isBannerMultiple

  let contentArray
  const setupContent = (teaserTextValue: string) => {
    const textContent =
      teaserTextValue &&
      teaserTextValue.replaceAll('<div>', '').replaceAll('</div>', '').replaceAll('<p>', '').replaceAll('</p>', '')
    contentArray = textContent && textContent.split('|')
    if (contentArray) {
      for (let i = 0; i < contentArray.length; i++) {
        contentArray[i] = `<span className='content${i + 1}'>${contentArray[i]}</span>`
      }
    }
  }
  setupContent(teaserTextValue)

  const termsAndConditionContent = (
    <TermsAndConditionsCta
      parentbg={parentbg || 'light'}
      overlayTextAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
      alignment={returnViewType}
      terms={moduleTeaser?.targetsTermsAndConditions}
    />
  )

  const showTermsAndConditions = (viewtype: IViewType | undefined) => {
    if (termsAndConditionsVisible) {
      switch (viewtype) {
        case 'fullwidth-promo-banner':
        case 'fullwidth-promo-banner-2':
        case 'fullwidth-promo-banner-3':
        case 'fullwidth-banner-collection-promo':
        case 'fullwidth-banner-collection-promo-multiple':
        case 'landscape-promo-banner':
        case 'landscape-promo-banner-2':
        case 'landscape-promo-banner-3':
        case 'landscape-banner-collection-promo':
        case 'landscape-banner-collection-promo-multiple':
        case 'top-page-promo':
        case 'top-page-promo-2':
        case 'top-page-promo-3':
        case 'top-page-banner-collection-promo':
        case 'top-page-banner-collection-promo-multiple':
          return (isMobile || isTabletWidthBelowOrEqualTo768) && termsAndConditionContent
        case 'fullwidth-promo-banner-4':
          return (isDesktop || isMobile || isTabletWidthBelowOrEqualTo768) && termsAndConditionContent
        case 'squat-banner':
          return (isMobile || isTabletWidthBelowOrEqualTo768) && termsAndConditionContent
        default:
          return null
      }
    } else return null
  }

  const content = (
    <>
      {teaserIcon && (
        <ContentCmsIcon alignment={teaserOverlayTextAlignValueCMS} viewtype={returnViewType}>
          <CmsIcon teaserIcon={teaserIcon} />
        </ContentCmsIcon>
      )}
      <CmsCountdown styleOverride={countdownStyles} teaser={moduleTeaser} />
      {(teaserPreTitle || teaserTitleValue2) && (!isTopPage || !(isDesktop || isTabletWidthAbove768)) && (
        <StyledPreTitle
          data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
          variant={promoteToH1 ? 'h2' : 'h3'}
          textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValueCMS)}
        >
          {isFullWidth && !teaserPreTitle ? teaserTitleValue2 : teaserPreTitle}
        </StyledPreTitle>
      )}
      {teaserTitleValue && (
        <StyledTitle
          data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
          variant={promoteToH1 ? 'h1' : 'h2'}
          fontWeight="bold"
          textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValueCMS)}
          viewtype={returnViewType}
        >
          {teaserTitleValue}
        </StyledTitle>
      )}
      <TextContainerWrapper textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}>
        {teaserTextValue ? (
          isFullWidth && contentArray.length > 1 ? (
            <TextWrapper
              data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
              dangerouslySetInnerHTML={{
                __html: `<div>${contentArray && contentArray.join('')}</div>`,
              }}
              textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
              viewtype={returnViewType}
            />
          ) : (
            <TextWrapper
              data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
              dangerouslySetInnerHTML={{ __html: teaserTextValue }}
              textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValueCMS)}
              viewtype={returnViewType}
            />
          )
        ) : null}
        {moduleCollection &&
          moduleCollectionTeaser &&
          moduleCollectionTeaser.map((teaser, index) => {
            teaser[teaserText] && setupContent(teaser[teaserText])
            return (
              <TextPromoWrapper
                key={index}
                textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
                viewtype={returnViewType}
              >
                {teaser.name === 'Divider' ? (
                  <Divider key={index} textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)} />
                ) : teaser[teaserText] && contentArray.length > 1 ? (
                  <TextWrapper
                    key={index}
                    data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
                    dangerouslySetInnerHTML={{
                      __html: `<div>${contentArray && contentArray.join('')}</div>`,
                    }}
                    textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
                    variantName={teaser.name as ILXTeaserName}
                  />
                ) : (
                  <TextWrapper
                    key={index}
                    data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
                    dangerouslySetInnerHTML={{ __html: teaser[teaserText] }}
                    textAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
                    variantName={teaser.name as ILXTeaserName}
                  />
                )}
                {teaser[teaserTitle3] && <>{multipleTeaserDisplay(teaser[teaserTitle3], 'teaserTitle3')}</>}
              </TextPromoWrapper>
            )
          })}
        {teaserTitleAccess2 && teaserTitleValue2 && <>{multipleTeaserDisplay(teaserTitleValue2, 'teaserTitle2')}</>}
        {teaserTitleAccess3 && teaserTitleValue3 && <>{multipleTeaserDisplay(teaserTitleValue3, 'teaserTitle3')}</>}
        {teaserTitleAccess4 &&
          teaserTitleValue4 &&
          moduleCollectionViewType !== 'multiple-percent-off-text-without-code' && (
            <>{multipleTeaserDisplay(teaserTitleValue4, 'teaserTitle4', true)}</>
          )}
        {teaserTextAccess2 && teaserTextValue2 && <>{multipleTeaserDisplay(teaserTextValue2, 'teaserText2')}</>}
      </TextContainerWrapper>
      {productTeaser && productTeaser.viewtype === 'box-product-carousel' && (
        <StyledSwiperContent>
          <StyledProductContainer>
            <StyledSwiper
              slidesPerView={1}
              slidesPerGroup={1}
              loop={false}
              pagination={false}
              navigation={true}
              modules={[Navigation]}
              spaceBetween={0}
            >
              {(productTeaser as ICMCollection).teasableItems.map((element, index) => (
                <SwiperSlide key={index}>
                  <ProductItemWithSlide
                    crop={'ONE_ITEM_PRODUCT_CAROUSEL'}
                    teaser={element as ILXTeaser}
                    viewType={(productTeaser as ICMCollection).viewtype}
                  ></ProductItemWithSlide>
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </StyledProductContainer>
        </StyledSwiperContent>
      )}
      {teaserLXCallToActionSettings.length > 0 && (
        <StyledCTAContainer
          overlayTextAlign={getTeaserOverlayTextAlign('center')}
          data-element-id={`X_X_Text_Module_${teaserIndex}Placement_Banner_${teaserLXCallToActionSettings[0].callToActionText?.replaceAll(
            ' ',
            '_'
          )}_CTA0`}
        >
          {showCta &&
            teaserLXCallToActionSettings.map((actionSettings, index) => (
              <CmsCta
                dataElementId={`X_X_Text_Module_${teaserIndex}Placement_Banner_${actionSettings.callToActionText?.replaceAll(
                  ' ',
                  '_'
                )}_CTA${index}`}
                key={`cms-content__text-module--cta-${index}`}
                teaserCtaSetting={actionSettings}
              />
            ))}
        </StyledCTAContainer>
      )}
      {teaserTitleAccess4 &&
        teaserTitleValue4 &&
        moduleCollectionViewType === 'multiple-percent-off-text-without-code' && (
          <>{multipleTeaserDisplay(teaserTitleValue4, 'teaserTitle4', false)}</>
        )}
      {showTermsAndConditions(returnViewType)}
    </>
  )
  return (
    <TextModuleWrapper
      overlaytxtalign={teaserOverlayTextAlignValueCMS}
      overlaysettings={teaserOverlaySettingsValue}
      istextoverlay={isTextOverlay}
      teaseroverlaystyle={teaserOverlayStyleValue}
      teaserbackground={teaserbgvalue}
      termsconditionvisible={termsAndConditionsVisible}
      viewtype={returnViewType}
      placement={viewType || returnViewType || 'default'}
    >
      <StyledTextModuleContainer viewtype={returnViewType}>{content}</StyledTextModuleContainer>
      {termsAndConditionsVisible && placement?.viewtype !== 'squat-banner' && !isFullWidth
        ? termsAndConditionContent
        : null}
    </TextModuleWrapper>
  )
}

export default TextModule
