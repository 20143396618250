import styled from '@mui/material/styles/styled'

export const StyledGridOfProductsContainer = styled('div', {
  name: 'GridOfProducts',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: theme.spacing(8),
  paddingInline: theme.spacing(16),
  '* div': {
    margin: 0,
  },
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(8),
  },
  [theme.breakpoints.down(1025)]: {
    paddingInline: theme.spacing(8),
  },
  [theme.breakpoints.down(769)]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down(521)]: {
    paddingInline: theme.spacing(4),
  },
  [theme.breakpoints.down(391)]: {
    gap: theme.spacing(4),
  },
  [theme.breakpoints.down(321)]: {
    '& > div': {
      height: '100%'
    }
  },
}))

export const StyledGridOfProductsItem = styled('div', {
  name: 'GridOfProducts',
  slot: 'Item',
  shouldForwardProp: (prop) => prop !== 'columnAmount' && prop !== 'gridOfProducts',
})<{ gridOfProducts?: boolean }>(({ theme, gridOfProducts = false }) => ({
  width: '100%',
  '* div': {
    position: 'static'
  },
  '.product-price': {
    paddingBottom: gridOfProducts ? theme.spacing(8) : 0
  },
  '.arn-product-tile__header': {
    paddingTop: theme.spacing(8),
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '41vw',
  },
  '&.cly-products-category-tabs': {
    [theme.breakpoints.down('sm')]: {
      width: '51vw'
    }
  },
  [theme.breakpoints.down(600)]: {
    '.product-price': {
      paddingBottom: gridOfProducts ? theme.spacing(4) : 0
    },
    '.arn-product-tile__header': {
      paddingTop: theme.spacing(4),
    },
  }
}))

export const StyledProductCarouselGrid = styled(StyledGridOfProductsItem, {
  name: 'ProductCarouselGrid',
  slot: 'Item',
  shouldForwardProp: (prop) => prop !== 'columnAmount' && prop !== 'gridOfProducts',
})<{ gridOfProducts?: boolean }>(({ theme }) => ({
  [theme.breakpoints.down(769)]: {
    width: '100%',
  },
}))