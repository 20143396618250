import { useContext } from 'react'
import clsx from 'clsx'
import { TextModuleBanner } from '../TextModuleBanner'
import { ShoppableTeaser } from '../ShoppableTeaser'
import { hasOverlay, hasTermsAndConditions, isShoppableTeaser } from '@utils/cms/teaser'
import { ImageCropTypes } from '@constants/ui'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { CmsMedia } from '../CmsMedia'
import styles from '../Media/MediaWrapper.module.scss'
import { TermsAndConditions } from '../TermsAndConditions'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'

type GenericBannerProps = {
  crop: ImageCropTypes
  className?: string
  lazy?: boolean
  hideTermsAndConditions?: boolean
  termsAndConditionsClassName?: string
  textModuleClassName?: string
  videoWrapperClassName?: string
}

const GenericBanner: React.FC<GenericBannerProps> = props => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const teaser = data.placement?.items?.find(isLXTeaser)

  const {
    className,
    crop,
    hideTermsAndConditions = false,
    lazy,
    termsAndConditionsClassName,
    textModuleClassName,
    videoWrapperClassName,
  } = props

  const {
    media,
    teaserOverlay1Settings,
    teaserOverlay2Style,
    teaserOverlay2TextAlign,
    teaserLXCallToActionSettings,
    teaserBackground,
  } = teaser || {}
  const isShoppable = isShoppableTeaser(teaser)
  const hasTerms = hasTermsAndConditions(teaser)

  return (
    <section
      className={clsx(
        'cms-section',
        styles['media-wrapper'],
        className,
        teaserOverlay1Settings,
        teaserOverlay2Style,
        teaserOverlay2TextAlign,
        teaserBackground,
        {
          [`has-cta-${teaserLXCallToActionSettings?.length}`]: true,
          'no-cta': !teaserLXCallToActionSettings?.length,
          'is-shoppable': isShoppable,
          'has-terms': hasTerms,
        }
      )}
    >
      {isShoppable && <ShoppableTeaser />}
      <CmsMedia
        media={media}
        hasOverlay={hasOverlay(teaser)}
        lazy={lazy}
        cropType={crop}
        videoClassName={videoWrapperClassName}
      />
      <TextModuleBanner className={textModuleClassName} hideTermsAndConditions />
      {!hideTermsAndConditions && <TermsAndConditions className={termsAndConditionsClassName} teaser={teaser} />}
    </section>
  )
}

export default GenericBanner
