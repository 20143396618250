import type { ITeaserCallToAction } from '../types/cmsPlacement/LXTeaser'
import { ctaStylesMap } from '../constants/ui'
import { removeStoreSegment } from './url'
import config from '../configs'

export const getCTAVariant = (cta: ITeaserCallToAction) => {
  const ctaStyle = cta?.style?.split('--')[1] || 'primary'
  return ctaStylesMap[ctaStyle]
}

export const getCTAToUrl = (basePath: string, cta: ITeaserCallToAction) => {
  // @ts-ignore
  const formattedUrl = removeStoreSegment(`${cta.target.formattedUrl || ''}`)

  if (
    cta.target.type === 'CMExternalPage' ||
    cta.target.type === 'CMExternalChannel' ||
    cta.target.type === 'CMChannel' ||
    cta.target.type === 'CMExternalProduct'
  ) {
    return `${basePath}/${formattedUrl}`
  }

  if (cta.target.type === 'CMDownload') return `${config.cmsImageServerUrl}${cta.target.fullyQualifiedUrl}`

  return formattedUrl
}
