import styled from '@mui/material/styles/styled'
import { GridContainer, StyledTypography } from '../../UI'
import { ColorVariant, ShapeVariant } from '../../UI/Carousel/Carousel.type'
import CallToAction from '../call-to-action'
import { Swiper } from 'swiper/react'
import { mapControllerVariant, mapShapeVariant, mapVariant } from '../../UI/Carousel/Carousel.style'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const WrapperProductCarousel = styled(GridContainer, {
  name: 'ProductCarousel',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: '100%',
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}))

export const TitleProductCarousel = styled('div', {
  name: 'ProductCarousel',
  slot: 'Title',
})(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 24,
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.down(888)]: {
    fontSize: theme.spacing(5),
  },
  [theme.breakpoints.up(769)]: {
    lineHeight: theme.spacing(7.5),
  },
}))

export const ContentProductCarousel = styled('div', {
  name: 'ProductCarousel',
  slot: 'ContentList',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const ContentProduct = styled('div', {
  name: 'ProductCarousel',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}))

export const ContentImage = styled('div', {
  name: 'ProductCarousel',
  slot: 'ContentImage',
})(() => ({
  width: 120,
}))

export const ProductListLink = styled('span', {
  name: 'ProductCarousel',
  slot: 'ProductListLink',
})(() => ({
  display: 'flex',
  justifyContent: 'end',
}))

export const StyledProductItem = styled('div', {
  name: 'ProductCarousel',
  slot: 'ProductItem',
})<{ crop: string }>(({ theme, crop }) => ({
  paddingTop: crop !== 'G1_W_2_ITEMS' ? theme.spacing(8) : '0',
  paddingBottom: crop !== 'G1_W_2_ITEMS' ? theme.spacing(8) : '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    overflow: 'hidden',
  },
}))
export const StyledGridProductItem = styled('div', {
  name: 'ProductGrid',
  slot: 'GridProductItem',
})<{ crop: string }>(({ theme, crop }) => ({
  paddingTop: crop !== 'G1_W_2_ITEMS' ? theme.spacing(8) : '0',
  paddingBottom: crop !== 'G1_W_2_ITEMS' ? theme.spacing(8) : '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up(1281)]: {
    width: theme.spacing(66),
  },
  [theme.breakpoints.between(1024, 1279)]: {
    width: theme.spacing(54),
  },
}))

export const StyledGridCategoryItem = styled('div', {
  name: 'ProductGrid',
  slot: 'GridCategoryItem',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
export const StyledCTAContainerForProductCarousel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
  alignItems: 'center',
  justifyContent: 'center',
  '& > a': {
    width: 'auto',
    padding: 0,
    minWidth: theme.spacing(29.5),
    height: `${theme.spacing(9.5)} !important`,
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  [theme.breakpoints.down(769)]: {
    width: '100%',
    '& > a': {
      minWidth: '97.5%',
      marginLeft: `-${theme.spacing(2)}`,
      marginRight: 0,
    },
    marginLeft: 0,
    marginRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    width: '102.5%',
  },
}))

export const StyledSwiper = styled(Swiper)<{
  paginationwidth?: number
  colorvariant?: ColorVariant
  paginationvariant?: ColorVariant
  shapevariant?: ShapeVariant
  darkmode?: number
  slideviewpercent?: number
  showNextSlide?: boolean
  viewtype?: IViewType
}>(
  ({
    theme,
    paginationwidth,
    colorvariant = 'light',
    paginationvariant = 'dark',
    shapevariant = 'round',
    darkmode = 0,
    slideviewpercent = 0,
  }) => ({
    marginBottom: 40,
    minHeight: '100%',
    padding: '0 64px !important',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(16),
      padding: `0 ${theme.spacing(8)} !important`,
    },
    '.swiper-wrapper': {
      paddingBottom: theme.spacing(10),
    },
    '.swiper-slide': {
      display: 'flex',
      flexDirection: 'column',
      width: `${slideviewpercent > 0 ? slideviewpercent + '% !important' : 'unset'} `,
      [theme.breakpoints.down(769)]: {
        '.arn-product-tile__inner': {
          width: '65%',
          display: 'flex',
          margin: 'auto',
          marginTop: theme.spacing(6),
        },
      },
      [theme.breakpoints.down(500)]: {
        transform: `translateX(${theme.spacing(0)}) !important`,
        '.arn-product-tile__inner': {
          width: '100%',
        },
      },
    },
    '.swiper-button-prev, .swiper-button-next': {
      width: theme.spacing(16),
      height: '104%', //Just a container
      top: 18,
      '&:after': {
        color: mapVariant(theme, colorvariant, shapevariant).text,
        fontSize: mapShapeVariant(theme, shapevariant).fontSize,
        borderRadius: mapShapeVariant(theme, shapevariant).borderRadius,
        background: mapVariant(theme, colorvariant, shapevariant).background,
        width: 32,
        fontWeight: 'bolder',
        boxShadow: mapShapeVariant(theme, shapevariant).boxShadow,
        display: 'flex',
        alignItems: 'center',
        height: mapShapeVariant(theme, shapevariant).height,
      },
      '&:hover:after': {
        background: mapVariant(theme, colorvariant, shapevariant).hoverBackground,
        color: mapVariant(theme, colorvariant, shapevariant).hoverText,
      },
      [theme.breakpoints.down(769)]: {
        display: 'none',
      },
    },
    '.swiper-button-next': {
      right: '0',
      background: mapControllerVariant(theme, darkmode === 1 ? 'dark' : 'light', 'to left').buttonBackground,
      '&:after': {
        padding: '10px 14px',
      },
    },
    '.swiper-button-prev': {
      left: '0px',
      background: mapControllerVariant(theme, darkmode === 1 ? 'dark' : 'light', 'to right').buttonBackground,
      '&:after': {
        padding: '10px 12px',
      },
    },
    '.swiper-pagination': {
      marginBottom: theme.spacing(7),
      width: '100%',
      zIndex: 2,
    },
    '.swiper-pagination-bullet': {
      borderRadius: 0,
      width: `${paginationwidth ? paginationwidth : 60}px`,
      height: 2,
      opacity: 1,
      background: mapVariant(theme, paginationvariant).hoverPaginationBackground,
      marginBottom: '-40px !important',
      '&.swiper-pagination-bullet-active': {
        background: mapVariant(theme, paginationvariant).background,
      },
      [theme.breakpoints.down(769)]: {
        width: `${paginationwidth ? paginationwidth : 14}px`,
      },
    },
  })
)

export const StyledViewAllContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
  alignItems: 'center',
  justifyContent: 'center',
  '& > a': {
    width: 'auto',
    minWidth: `${theme.spacing(17.5)} !important`,
    height: theme.spacing(3.5),
    padding: `${theme.spacing(3)} ${theme.spacing(6)} !important`,
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))

export const ProductItemTeaserTitle = styled(StyledTypography, {
  name: 'ProductCarousel',
  slot: 'ProductItemTeaserTitle',
})(({ theme }) => ({
  fontWeight: 600,
  textAlign: 'center',
  fontSize: 14,
  '&.title2': {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: theme.spacing(6),
  },
  '&.title3': {
    fontWeight: 400,
    lineHeight: theme.spacing(6),
  },
  '&.title4': {
    fontSize: 16,
    lineHeight: theme.spacing(6),
  },
}))

export const ProductTeaserImage = styled('div')<{ crop: string }>(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: theme.spacing(115),
  },
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(115),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(70),
  },
}))

export const ProductTeaserImage2 = styled('div')<{ crop?: string }>(({ theme }) => ({
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(84),
    [theme.breakpoints.up(981)]: {
      width: theme.spacing(54),
    },
    [theme.breakpoints.between(769, 980)]: {
      width: theme.spacing(44),
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(40),
    [theme.breakpoints.down(340)]: {
      width: theme.spacing(30),
    },
  },
}))

export const StyledCTA = styled(CallToAction, {
  name: 'ProductCarousel',
  slot: 'StyledCTA',
})(() => ({
  height: 40,
  padding: 0,
}))

export const ProductCarouselTabsContainer = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(16)} ${theme.spacing(16)} ${theme.spacing(4)} `,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(16)} ${theme.spacing(8)} ${theme.spacing(4)} `,
  },
  [theme.breakpoints.down(769)]: {
    padding: `${theme.spacing(10)} ${theme.spacing(8)} 0`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(10)} ${theme.spacing(4)} 0`,
  },
}))

export const ProductCarouselHeader = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(16)} ${theme.spacing(16)} 0`,
  [theme.breakpoints.down(769)]: {
    padding: `${theme.spacing(10)} ${theme.spacing(10)} 0`,
  },
}))

export const ProductCarouselStyledTitle = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(6),
  fontWeight: '700',
  lineHeight: '30px',
  textAlign: 'center',
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down(769)]: {
    fontSize: theme.spacing(5),
  },
}))

export const ProductHeaderGridContainer = styled(GridContainer, {
  name: 'ProductHeader',
  slot: 'GridContainer',
})(({ theme }) => ({
  marginBottom: theme.spacing(9),
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.down(500)]: {
    marginBottom: theme.spacing(0),
  },
}))

export const ProductCarouselGridContainer = styled(GridContainer, {
  name: 'ProductCarouselGrid',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'subcontainer' && prop !== 'gap' && prop !== 'container',
})<{
  subcontainer?: boolean
  gap?: 0 | 1 | 4 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96
  container?: boolean
}>(({ theme }) => ({
  [theme.breakpoints.down(769)]: {
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(4),
    paddingBottom: theme.spacing(10),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(8),
    gridColumnGap: 0,
  },
}))
